export const PATH_NAME = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  COMPLETE_INFO_EMAIL: '/complete-info-email',
  COMPLETE_INFO_PHONE: '/complete-info-phone',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  USER_INFO_EXPERIENCE: '/user-info/experience',
  USER_INFO_INTRODUCTION: '/user-info/introduction',
  USER_INFO_SCHOOL: '/user-info/school',
  USER_INFO_SCIENTIFIC: '/user-info/scientific',
  USER_INFO_SOCIAL_LINK: '/user-info/social-link',
  NOTIFICATION: '/user/notification',
  USER: '/user',
  SETTING: '/user/setting',
  SCAN_QR: '/my-qr',
  INCOMMING: '/incomming',
  PROFILE: '/user/[uid]',
  FEED: '/feed',
  NETWORK: '/network',
  SEARCH: '/network/search',
  SUGGEST: '/network/suggest',
  LEARNING_HUB: '/learning-hub',
  REWARD: '/reward',
  SHARE_WITH_ME: '/share-with-me',
  MY_GROUPS_DETAIL: '/my-groups/[gid]',
  MY_GROUPS: '/my-groups',
  MY_GROUPS_SEARCH: '/my-groups/[gid]/search',
  DOCCEN_MARKET: '/doccen-market',
  TRASH: '/trash',
  HOMEPAGE: '/homepage',
  POLICY: '/policy',
  TERM_OF_USE: '/policy/term-of-use',
  TERM_FOR_CONTENT_PARTNER: '/policy/term-for-content-partner',
  TERM_OF_PRIVATE: '/policy/term-of-private',
  TERM_OF_PAYMENT: '/policy/term-of-payment',
  TERM_OF_COMPLAINT: '/policy/term-of-complaint',
  TERM_OF_VNPAY: '/policy/term-of-vnpay',
  PRIVACY_RULE: '/homepage/privacy-rule',
  CONTACT: '/homepage/contact',
  STORAGE: '/storage',
  COURSE: '/course',
  FOLDER_DETAIL: '/folder-detail',
  COURSE_DETAIL: '/my-groups/[gid]/course/[cid]',
  COURSE_MANAGEMENT: '/my-groups/[gid]/course-management',
  COURSE_MANAGEMENT_DETAIL:
    '/my-groups/[gid]/course-management/course-detail/[cid]',
  COURSE_MANAGEMENT_DETAIL_PLAYLIST_DETAIL:
    '/my-groups/[gid]/course-management/playlist/[playlistId]',
  LESSON: '/my-groups/[gid]/course/[cid]/lesson/[lid]',
  QUIZ: '/my-groups/[gid]/course/[cid]/quiz/[qid]',
  VISITOR_FEED: '/feed',
  HOME_FEED: '/home',
  HOME_FEED_POST_DETAIL: '/home/[pid]',
  HOME_FEED_SEARCH: '/home/search',
  EXTEND: '/extend',
  COURSE_MANAGEMENT_DETAIL_CREATE_QUIZ:
    '/my-groups/[gid]/course-management/course-detail/[cid]/create-quiz/[qid]',
  CREATE_H5P:
    '/my-groups/[gid]/course-management/course-detail/[cid]/create-h5p',
  PLAY_H5P: '/my-groups/[gid]/course/[cid]/h5p/[h5pid]',
  PAYMENT_MANUAL: '/homepage/payment-manual',
  NOT_FOUND: '/404',
  FORBIDDEN: '/403',
  ROADMAP_MARKETING_PHARMACY: '/course/roadmap/pharmarcy-marketing',
  ROADMAP_MEDICAL_COURSE: '/course/roadmap/medical-course',
  PARACLINICAL_INTERNAL_MEDICINE:
    '/course/roadmap/paraclinical_internal_medicine',
  PHARMACY_PHARMACISTS: '/course/roadmap/pharmacy_pharmacists',
  VERIFY_ACCOUNT: '/verify-account',
  AI: '/ai',
  AI_DETAIL_CONVERSATION: '/ai/[chatId]',
};
