/* eslint-disable no-unused-vars */
//this should be an array instead of an object. i had to use object.entries, which is pretty cồnh kềnh
export const KEY_SEE_MORE = {
  school: 'school',
  major: 'major',
  workPlace: 'workPlace',
  random: 'random',
  biz: 'biz',
};

export const NOTIFICATIONS = 'notifications';

export const SAME_INFO_SUGGEST = 'same-info-suggest';
export const RANDOM_SUGGEST = 'random-suggest';
export const USER_SEARCH = 'user-search';
export const USER_INFO = 'user-info';
export const USER_INFO_BY_ID = 'user-info-by-id';
export const CELEB_SUGGEST = 'celeb-suggest';
export const REFERED_USERS = 'refered-users';

export const USER_PLAN_QUERY_KEY = 'user_plan';

export const FAMILIAR_CHALLENGE = 'familiar-challenge';
export const DAILY_CHALLENGE = 'daily-challenge';
export const DOCCOIN = 'doccoin';
export const REWARD_MISSION = 'reward-mission';
export const ACCESSIBLE_USERS = 'accessible-users';

export const FILES_LIST = 'files-list';
export const FOLDER_LIST = 'folder-list';
export const FOLDER_INFO = 'folder-info';

export const FILES_LIST_TRASH = 'files-list-trash';
export const FOLDER_LIST_TRASH = 'folder-list-trash';

export const FILES_LIST_MY_SPACE = 'file-list-my-space';
export const FOLDER_LIST_MY_SPACE = 'folder-list-my-space';

export const FOLDER_LIST_SHARE_WITH_ME = 'folder-list-share-with-me';
export const FILES_LIST_MY_GROUP = 'file-list-my-group';

export const UPDATE_FILE_NAME = 'update_file_name';
export const GET_INDEPENDENT_IMAGES = 'get-independent-images';
export const GET_EXISTING_FOLDERS = 'get-existing-folders';
export const GET_INDEPENDENT_FILES = 'get-independent-files';
export const MOVE_FILE_TO_TRASH = 'move-to-trash';

export const GROUP_ROLE_ACCESS = 'group-role-access';
export const GROUP_PLAN = 'group_plan';
export const GROUP_HASHTAGS = 'group_hashtags';

export const MEMBER_LIST = 'MEMBER_LIST';
export const REQUEST_MEMBER_LIST = 'REQUEST_MEMBER_LIST';

export const COURSE_LIST = 'course-list';
export const COURSE_INFO = 'course-info';
export const COURSE_INFO_MANAGE = 'course-info-manage';
export const COURSE_LIST_TRASH = 'course-list-trash';
export const COURSE_STATUS_PUBLISHED = 'course-status-published';
export const COURSE_STATUS_DRAFT = 'course-status-drafts';
export const COURSE_STATUS_HIDDEN = 'course-status-hidden';
export const COURSE_COMPLETED_RESULT = 'course-complete-result';

export const LESSON_DETAILS = 'lesson-details';
export const COURSE_LIST_CERT = 'course-list-cert';

export const RECENTLY_VIEW_LESSON_LIST = 'recently_view_lesson_list';

export const CERTIFICATE_REQUEST_LIST = 'certificate_request_list';
export const COURSE_COMPLETED_LIST = 'course_complete_list';
export const CERTIFICATE_REQUEST = 'certificate_request';
export const DEFAULT_CERTIFICATE_INFO = 'default_certificate_info';

export enum RQCommentCacheKey {
  LessonCommentList = 'LessonCommentList',
  LessonSubComment = 'LessonSubComment',
  LessonPinnedComment = 'LessonPinnedComment',
}

export enum RQPostCommentKey {
  PostCommentList = 'PostCommentList',
  PostSubComment = 'PostSubComment',
  PostPinnedComment = 'PostPinnedComment',
}

export const FEED_TOPIC_LIST = 'feed_topic_list';
export const FEED_USER_TOPIC_LIST = 'feed_user_topic_list';
export const FEED_GROUPS_SUGGESTION = 'feed_groups_suggestion';
export const FEED_LIST_POST_VISITOR_FEED = 'feed_list_post_visitor_feed';
export const FEED_LIST_PERSONAL_USER_WALL = 'feed_list_personal_user_wall';
export const FEED_LIST_BUSINESS_USER_WALL = 'feed_list_business_user_wall';
export const FEED_LIST_POST_HOME_FEED = 'feed_list_post_home_feed';
export const FEED_LIST_POST_IN_GROUPS = 'feed_list_post_in_groups';
export const FEED_POST_DETAIL = 'feed_post_detail';
export const FEED_HASHTAG_LIST = 'feed_hashtag_list';
export const FEED_SEARCH_LIST_GROUP = 'feed_search_list_group';
export const FEED_SEARCH_LIST_FRIEND = 'feed_search_list_friend';
export const FEED_SEARCH_LIST_POST = 'feed_search_list_post';
export const HOME_SEARCH_LIST_FRIEND = 'home_search_list_friend';
export const ONBOARD_GROUPS_SUGGESTION = 'onboard-groups-suggestion';
export const ONBOARD_TOPIC_LIST = 'onboard-topic-list';
export const ONBOARD_STATUS = 'onboard-status';

export const USER_JOINED_GROUPS_USER_WALL = 'user_joined_groups_user_wall';
export const USER_JOINED_GROUPS = 'user_joined_groups';

export const TMS_USER_ID = 'tms_user_id';

export const GROUP_SEARCH_LIST_POST = 'group_search_list_post';
export const GROUP_SEARCH_LIST_MEMBER = 'group_search_list_member';
export const GROUP_SEARCH_LIST_COURSE = 'group_search_list_course';
export const GROUP_SEARCH_LIST_DOCUMENT = 'group_search_list_document';

// course page query keys
export const COURSE_COMPLETED_COURSES = 'course_completed_courses';
export const COURSE_IN_PROGRESS_COURSES = 'course_in_progress_courses';
export const COURSE_ALL_COURSES = 'course_all_courses';

export const QUIZ_INFO = 'quiz_info';
export const QUIZ_TESTING_QUESTIONS = 'quiz_testing_questions';
export const QUIZ_REVIEW_RESULTS = 'quiz_review_results';

export const QUIZ_QUESTION_DETAIL_OWNER = 'quiz_question_detail_owner';

export const QUIZ_DETAIL = 'quiz_detail';
export const QUIZ_QUESTIONS_LIST = 'quiz_questions_list';

export const BUSINESS_PROFILE_GROUPS = 'business_profile_groups';
export const BUSINESS_OUTSTANDING_PROFILE = 'business_outstanding_profile';

export const REQUEST_CERTIFICATE_DEFAULT_INFO =
  'request_certificate_default_info';

export const ENTERPRISE_PROFILE_MEMBER_LIST = 'enterprise_profile_member_list';
export const ENTERPRISE_USER_FOLLOW_LIST = 'enterprise_user_follow_list';
export const ENTERPRISE_SUGGESTED_COURSES = 'enterprise_suggested_courses';
export const ENTERPRISE_HIGHLIGHT_COURSES = 'enterprise_highlight_courses';
export const ENTERPRISE_COURSES = 'enterprise_courses';
export const ENTERPRISE_HOMEPAGE_RECOMMEND_USER =
  'enterprise_homepage_recommend_user';

export const ENTERPRISE_GROUP_COURSE_LIST = 'enterprise_group_course_list';

export const COURSE_PLAYLIST = 'course_playlist';
export const DETAIL_COURSE_PLAYLIST = 'detail_course_playlist';
export const AVAILABEL_COURSES_FOR_PLAYLIST = 'availabel_courses_for_playlist';
export const PROVIENCE_LIST = 'proviences';
export const DISTRICT_LIST = 'districts';

export const QUIZ_COURSES_RECOMMEND = 'quiz_courses_recommend';

export const LESSON_DOCUMENTS = 'lesson_documents';
export const QUIZ_DOCUMENTS = 'quiz_documents';

export const CONVERSATION_LIST = 'conversation_list';
export const CONVERSATION_DETAIL = 'conversation_detail';
